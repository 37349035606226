<template>
  <div class="activities-page">
    <div
      style="min-height: 400px"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <div class="activities-title">{{ $t("hot_activity") }}</div>
      <el-row :gutter="20">
        <el-col :span="12" v-for="l in hotList" :key="l.id">
          <el-card shadow="hover">
            <div class="card-left">
              <img :src="l.thumbImageUrl || defaultImage" class="image" />
            </div>
            <div class="card-right">
              <div class="card-right--title" :title="l.title">
                {{ l.title }}
              </div>
              <div class="card-right--title-tip">
                <div>
                  {{ transform(l.startDate) }}-{{ transform(l.endDate) }}
                </div>
                <div>
                  {{ $t("task_reward") }}：<span class="reward-gold"
                    >{{ l.minPoints }}-{{ l.maxPoints }}{{ $t("gold") }}</span
                  >
                </div>
              </div>
              <div class="card-right--content" :title="l.summary">
                {{ l.summary }}
              </div>
              <div class="card-right--footer">
                <el-button
                  type="primary"
                  size="small"
                  @click="takeIn(l)"
                  round
                  >{{ $t("immediately_take_in") }}</el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="activities-title">{{ $t("expired_activity") }}</div>
      <el-row :gutter="20">
        <el-col :span="12" v-for="l in expireList" :key="l.id">
          <el-card shadow="hover" class="expire-ac">
            <div class="card-left">
              <img :src="l.thumbImageUrl || defaultImage" class="image" />
            </div>
            <div class="card-right">
              <div class="card-right--title" :title="l.title">
                {{ l.title }}
              </div>
              <div class="card-right--title-tip">
                <div>
                  {{ transform(l.startDate) }}-{{ transform(l.endDate) }}
                </div>
                <div>
                  {{ $t("task_reward") }}：<span class="reward-gold"
                    >{{ l.minPoints }}-{{ l.maxPoints }}{{ $t("gold") }}</span
                  >
                </div>
              </div>
              <div class="card-right--content" :title="l.summary">
                {{ l.summary }}
              </div>
              <div class="card-right--footer">
                <el-button type="info" size="small" round>{{
                  $t("immediately_take_in")
                }}</el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { get } from "lodash-es";
import * as dayjs from "dayjs";
import defaultImage from ".././../../assets/images/huodong-default.png";
export default {
  data() {
    return {
      dialogVisible: false,
      hotList: [],
      expireList: [],
      defaultImage
    };
  },
  async created() {
    try {
      this.loading = true;

      const promises = [
        this.$http.get(`/api/v1/Activities?id=2`),
        this.$http.get(`/api/v1/Activities?id=3`)
      ];
      const [p1, p2] = await Promise.all(promises);
      this.hotList = get(p1, "data.list", []);
      this.expireList = get(p2, "data.list", []);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleClose() {},
    takeIn(activity) {
      if (activity.url) {
        window.open(activity.url);
      } else {
        return this.$router.push(`/pages/activities/${activity.id}`);
      }
    },
    transform(str) {
      return dayjs(str).format("YYYY.MM.DD");
    }
  },
  computed: {}
};
</script>
